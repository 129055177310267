import {Pipe, PipeTransform} from '@angular/core';
import {DATE_FORMAT, DBCCardTypes, Utils} from '../../shared/utils';
import * as moment from 'moment-timezone';
import {AuthService} from '../../global-services/auth.service';
import {LanguageService} from '../notification-language/language.service';
import {QR} from '../../qr/qr.model';
import {USER_GROUPS} from '../../user-account/user.model';
import {PLAN_TYPES, User} from 'app/user-account/user.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {TEXT_FIELD_VALIDATIONS} from 'kaizen-design-system';
import {Operand, SMART_RULES_TYPE, SmartRulesModel} from '../../campaign/models/smart-rules.model';
import {
    AllConditoinsType,
    DROPDOWN_TYPE,
    SmartRulesDropDownOptions3,
    SmartRulesTypeList
} from '../../campaign/smart-rules/smart-rules.utils';
import {
    CARD_PROPERTY_TYPES, DigitalBusinessCardSetting
} from '../../digital-business-card/digital-business-card-setting/digital-business-card-setting.model';
import {
    CardsOrganizationRestrictedFields,
    DBC_ORG_PERMISSION_OPTIONS
} from '../../user-account/user-organization.model';

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({name: 'isLinkValid'})
export class IsLinkValid implements PipeTransform {
    transform(url?: string): boolean {
        return Utils.urlRegex.test(url);
    }
}

@Pipe({name: 'isDeepLinkValid'})
export class IsDeepLinkValid implements PipeTransform {
    transform(url?: string): boolean {
        return Utils.deepUrlRegex.test(url);
    }
}

@Pipe({name: 'textHasURL'})
export class TextHasURL implements PipeTransform {
    transform(url?: string): boolean {
        return Utils.urlRegex.test(url);
    }
}

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({name: 'utilsObjectKeys'})
export class UtilsObjectKeys implements PipeTransform {
    transform(object: any): any {
        return Utils.ObjectKeys(object);
    }
}

@Pipe({name: 'getTagNames'})
export class GetTagNames implements PipeTransform {
    transform(qrCode: any): any {
        return qrCode['tag_data'].map(tag => tag['name']);
    }
}

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({name: 'trimValues'})
export class TrimValues implements PipeTransform {
    transform(dataString?: string): string {
        return dataString ? dataString.trim() : dataString;
    }
}

@Pipe({name: 'toLowerCase'})
export class ToLowerCase implements PipeTransform {
    transform(dataString?: string): string {
        return dataString ? dataString.toLowerCase() : dataString;
    }
}

@Pipe({name: 'getOrganizationName'})
export class GetOrganizationName implements PipeTransform {
    constructor(public authService: AuthService) {
    }

    transform(id: number): string {
        return (this.authService.getOrgByID(id)?.name || '');
    }
}

@Pipe({name: 'getPreviewUrl'})
export class GetPreviewUrl implements PipeTransform {
    transform(data: any): any {
        return data.getPreviewUrl();
    }
}

@Pipe({name: 'stringSubstr'})
export class StringSubstr implements PipeTransform {
    transform(str: string, n: any): any {
        return str ? str.substring(0, n) : '';
    }
}

@Pipe({name: 'sliceValues'})
export class SliceValues implements PipeTransform {
    transform(str: any, n: any): any {
        return str ? str.slice(0, n) : null;
    }
}

@Pipe({name: 'getTimezone'})
export class GetTimeZone implements PipeTransform {
    transform(timeZone: string): any {
        const offset = moment.tz(timeZone).format();
        return moment.tz(timeZone).format().substring((offset.length) - 6, offset.length);
    }
}

@Pipe({name: 'isErrorCSVRow'})
export class IsErrorCSVRow implements PipeTransform {
    transform(indexes: any): boolean {
        const row = indexes['row'];
        const error_rows = indexes['error_rows'] || [];

        // @ts-ignore
        if (error_rows.indexOf(row) === -1) {
            return false;
        }
        return true;
    }
}

@Pipe({name: 'isErrorCSVField'})
export class IsErrorCSVField implements PipeTransform {
    transform(indexes: any): boolean {
        const row = indexes['row'];
        const column = indexes['column'];
        const errors = indexes['errors'] || [];
        const error_rows = indexes['error_rows'] || [];

        // @ts-ignore
        if (error_rows.indexOf(row) === -1) {
            return false;
        }
        for (const err of errors) {
            if (err['column'] === column && err['row'] === row) {
                return true;
            }
        }
        return false;
    }
}

@Pipe({name: 'getLanguageCode'})
export class GetLanguageCodePipe implements PipeTransform {

    constructor(public languageService: LanguageService) {
    }

    transform(language): any {
        return this.languageService.getLanguageCode(language)
    }
}

@Pipe({name: 'validatePhoneNumber'})
export class ValidatePhoneNumber implements PipeTransform {
    transform(phoneNumber: string): any {
        const regexPhone = /^[\+]{0,1}[0-9\s]{5,15}[,]{0,1}[\s]{0,1}[0-9]{0,7}/
        if (phoneNumber.match(regexPhone)) {
            return true;
        } else{
            return false;
        }
    }
}

@Pipe({name: 'isOnHigherPlan'})
export class IsOnHigherPlan implements PipeTransform {
    transform(data: any, str: string): any {
        return data ? data.isOnHigherPlan(str) : null;
    }
}

@Pipe({name: 'isOnHigherDBCPlan'})
export class IsOnHigherDBCPlan implements PipeTransform {
    transform(user: User, plan: PLAN_TYPES, planId: string, planYearly: boolean): any {
        const yearly = planId.indexOf('YEAR') !== -1;
        const isOnHigherDBCPlan = user.isOnHigherDBCPlan(plan) || !user.isOnDBCPlan();
        if (yearly) {
            return isOnHigherDBCPlan;
        } else {
            if (isOnHigherDBCPlan && (user.customer_plan === plan)) {
                return isOnHigherDBCPlan && !planYearly && planId.indexOf('TRIAL') === -1;
            } else {
                return isOnHigherDBCPlan && planId.indexOf('TRIAL') === -1;
            }
        }
    }
}

@Pipe({name: 'decimalPrice'})
export class DecimalPrice implements PipeTransform {
    transform(data: any): any {
        return data ? (Math.round(data) / 100).toFixed(2) : '';
    }
}

@Pipe({name: 'isSAN'})
export class IsSAN implements PipeTransform {
    transform(data: any): any {
        return data ? data.isSAN() : null;
    }
}

@Pipe({name: 'getReadableGroup'})
export class GetReadableGroup implements PipeTransform {
    transform(data: any): any {
        return data.getReadableGroup();
    }
}
@Pipe({name: 'getReadableGroupFromType'})
export class GetReadableGroupFromType implements PipeTransform {
    transform(data: any): any {
        switch (data) {
            case USER_GROUPS.Admin:
                return 'Admin';
            case USER_GROUPS.Owner:
                return 'Owner';
            case USER_GROUPS.ReadOnly:
                return 'Viewer';
            case USER_GROUPS.SandBox:
                return 'Editor';
            default:
                return '';
        }
    }
}
@Pipe({name: 'isOwner'})
export class IsOwner implements PipeTransform {
    transform(data: any): any {
        return data.isOwner();
    }
}

@Pipe({name: 'isInvitePending'})
export class IsInvitePending implements PipeTransform {
    transform(user: any): any {
        return user.meta.hasOwnProperty('invitation_state') && user.meta['invitation_state'] === 'pending';
    }
}

@Pipe({name: 'getTypeString'})
export class GetTypeString implements PipeTransform {
    transform(qr: QR): any {
        return qr.getTypeString().toUpperCase();
    }
}

@Pipe({name: 'formatDate'})
export class FormatDate implements PipeTransform {
    transform( date: string , format: string = DATE_FORMAT.DATE_FORMAT_STRING): string {
        return moment(date).format(format);
    }
}

@Pipe({name: 'getSanitizedHtml'})
export class GetSanitizedHtml implements PipeTransform {
    constructor(private domSanitizer: DomSanitizer) {
    }
    transform(html: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }
}

@Pipe({
    name: 'getDateFormatString'
})
export class DateFormatStringPipe implements PipeTransform {
    transform(operand: any, startDate? , endDate?): string {
        if (!operand.__startDate__) {
            return ''
        }

        let dateFormat = ''
        dateFormat = moment(operand.__startDate__).format('MMM DD, YYYY');

        if (operand.__hasMultipleValues__) {
            dateFormat += ' - '
            dateFormat += moment(operand.__endDate__).format('MMM DD, YYYY');
        }
        return dateFormat;
    }
}

@Pipe({
    name: 'latLongRangePipe'
})
export class LatLongRangePipe implements PipeTransform {
    transform(operand: any): string {
        let result = '';
        if (typeof operand.__latitude__ === 'number') {
            const latitude = operand.__latitude__.toString().slice(0, 5)
            result += latitude;
        }
        if (typeof operand.__longitude__ === 'number') {
            const longitude = operand.__longitude__.toString().slice(0, 5)
            result += ', ' + longitude;
        }
        if (typeof operand.range === 'number') {
            result += ', ' +  operand.range + 'm';
        }
        return result;
    }
}

@Pipe({
    name: 'operandIsValid'
})
export class OperandIsValid implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return TEXT_FIELD_VALIDATIONS.ERROR;
        }
    }
}

@Pipe({
    name: 'objectToNameValue'
})
export class ObjectToNameValuePipe implements PipeTransform {
    transform(obj: Operand, dropdown_type: DROPDOWN_TYPE): {} {
        if (!obj) {
            return {name: 'Default', value: 'default'};
        }

        if (dropdown_type === DROPDOWN_TYPE.RULE_TYPE) {
            return SmartRulesTypeList.find(res => {
                return obj.field === res.value;
            });
        } else if (dropdown_type === DROPDOWN_TYPE.CONDITION_TYPE) {
            return AllConditoinsType.find(res => {
                return obj.comparison_operator === res.value
            })
        } else if (dropdown_type === DROPDOWN_TYPE.VALUE_TYPE) {
            if (obj.field === SMART_RULES_TYPE.COUNTRY) {
                return SmartRulesDropDownOptions3[obj.field].find(res => {
                    return obj.value[0] === res.name;
                })
            }
            return SmartRulesDropDownOptions3[obj.field].find(res => {
                return obj.value[0] === res.value
            })
        }
    }
}

@Pipe({
    name: 'operandHasValue'
})
export class OperandHasValuePipe implements PipeTransform {
    transform(operand: any): boolean {
        if (operand.field === SMART_RULES_TYPE.DAY || operand?.__hasMultipleValues__) {
            return operand.value.some(s => s.length > 0);
        } else {
            if (operand?.__hasMultipleValues__) {
                return operand.value[0].length && operand.value[1].length;
            }
            return operand.value[0].length;
        }
    }
}

@Pipe({
    name: 'smartRulesAreInvalid'
})
export class SmartRulesAreInvalidPipe implements PipeTransform {
    transform(smartRules: SmartRulesModel): boolean {
        if (!smartRules.default.target) {
            return true;
        }

        for (const rule of smartRules.rules) {
            if (!rule.destination.target) {
                return true;
            }
        }

        return false;
    }
}

@Pipe({
    name: 'getObjectNameFromType'
})
export class GetObjectNameFromTypePipe implements PipeTransform {
    transform(type: string): string {
        switch (type) {
            case 'qr_code':
                return 'QR Codes';
            case 'linkpage':
                return 'Linkpages';
            case 'form':
                return 'Forms';
            case 'landing_page':
                return 'Landing Pages';
            default:
                return 'All';
        }
    }
}

@Pipe({
    name: 'getReadableDate'
})
export class GetReadableDatePipe implements PipeTransform {
    transform(date: string): string {
        return moment(date).format(DATE_FORMAT.DATE_FORMAT_STRING);
    }
}


@Pipe({
    name: 'getURLWithoutHttps'
})
export class GetURLWithoutHttpsPipe implements PipeTransform {
    transform(url: string): string {
        return url.replace('https://', '');
    }
}


@Pipe({
    name: 'generateRandomId'
})
export class GanerateRandomId implements PipeTransform {
    transform(string: string): string {
        return `${string}-${Math.floor(Math.random() * 1000000)}`;
    }
}

@Pipe({
    name: 'disableFieldCheckPipeV2'
})
export class DisableFieldCheckPipeV2 implements PipeTransform {
    transform(hasWriterAccess, cardSetting, cardOrgSetting, cardType, priortizeOrgSettings, property) {
        if (!hasWriterAccess) {
            return true;
        }

        if (cardType === DBCCardTypes.TEMPLATE_CARDS) {
            switch (property) {
                case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                    return cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES || cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.NO;
            }

            return false;
        } else {
            // For other types BULK, MYCARDS, TEAMCARDS
            if (cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.YES || cardOrgSetting[property] === DBC_ORG_PERMISSION_OPTIONS.NO || (cardOrgSetting[property] === true)) {
                return true;
            } else {
                switch (property) {
                    case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                        return cardSetting[CARD_PROPERTY_TYPES.AUTODOWNLOAD];
                    case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                        return cardSetting[CARD_PROPERTY_TYPES.LEAD_COLLECTION];
                    case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                        return cardSetting[CARD_PROPERTY_TYPES.LOCATION_ENABLED];
                    case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                        return cardSetting[CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED];
                }
                return cardSetting[property] || false;
            }
        }
    }
}

@Pipe({
    name: 'inputIconPipeV2'
})
export class InputIconPipeV2 implements PipeTransform {
    transform(cardSettingValue: any,
        cardType: DBCCardTypes, priortizeOrgSettings: boolean,
        property: CARD_PROPERTY_TYPES,
        cardSettings: DigitalBusinessCardSetting,
        orgCardSettings: CardsOrganizationRestrictedFields = new CardsOrganizationRestrictedFields()): string {

        let iconToshow = '';

        switch (property) {
            case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
            case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
            case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
            case CARD_PROPERTY_TYPES.EMAIL_WALLET_PASS:
            case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                if ( orgCardSettings[property] === DBC_ORG_PERMISSION_OPTIONS.YES || orgCardSettings[property] === DBC_ORG_PERMISSION_OPTIONS.NO) {
                    iconToshow = 'fas fa-lock text-secondary';
                } else {
                    // Check for card setting which conflicts with organization
                    switch (property) {
                        case CARD_PROPERTY_TYPES.AUTODOWNLOAD_V2:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.AUTODOWNLOAD] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                        case CARD_PROPERTY_TYPES.LEAD_COLLECTION_V2:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.LEAD_COLLECTION] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                        case CARD_PROPERTY_TYPES.LOCATION_ENABLED_V2:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.LOCATION_ENABLED] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                        case CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED:
                            iconToshow = cardSettings[CARD_PROPERTY_TYPES.IP_LOCATION_ENABLED] ? 'fas fa-lock text-secondary pl-2' : '';
                            break;
                    }
                }
                break;
            default:
                // We will enforce org level settings if user has set it up in org settings page.
                if (orgCardSettings[property] && cardType !== DBCCardTypes.TEMPLATE_CARDS) {
                    iconToshow = 'fas fa-lock text-secondary pl-2';
                }
                iconToshow = cardSettings[property] ? 'fas fa-lock text-secondary pl-2' : iconToshow;
                break;
        }
        if (cardType === DBCCardTypes.TEMPLATE_CARDS) {
            return iconToshow === '' ? 'fas fa-lock-open unlock-color pl-2' : iconToshow;
        }
        return iconToshow;

    }
}

@Pipe({
    name: 'getRoundedOfNumberPipe'
})
export class GetRoundedOfNumberPipe implements PipeTransform {
    transform(number: string | number): string {
        // If number is a string, convert it to a number
        if (typeof number === 'string') {
            number = +number;
        }

        // If number is not a number, return it as it is
        if (isNaN(number)) {
            return number.toString();
        }

        // For numbers between 1 - 999, show as it is
        if (number < 1000) {
            return number.toString();
        }

        // For numbers between 1000 and 9999, show as x.xxK
        if (number >= 1000 && number <= 9999) {
            const result = (number / 1000).toFixed(2);
            return parseFloat(result) + 'k';
        }

        // For numbers between 10000 and 99999, show as xx.xK
        if (number >= 10000 && number <= 99999) {
            const result = (number / 1000).toFixed(1);
            return parseFloat(result) + 'k';
        }

        // For numbers between 100000 and 999999, show as xxxK
        if (number >= 100000 && number <= 999999) {
            const result = (number / 1000).toFixed(0);
            return parseFloat(result) + 'k';
        }

        // For numbers between 1000000 and 9999999, show as x.xxM
        if (number >= 1000000 && number <= 9999999) {
            const result = (number / 1000000).toFixed(2);
            return parseFloat(result) + 'M';
        }

        // For numbers between 10000000 and 99999999, show as xx.xM
        if (number >= 10000000 && number <= 99999999) {
            const result = (number / 1000000).toFixed(1);
            return parseFloat(result) + 'M';
        }

        // For numbers between 100000000 and 999999999, show as xxxM
        if (number >= 100000000 && number <= 999999999) {
            const result = (number / 1000000).toFixed(0);
            return parseFloat(result) + 'M';
        }

        // For numbers between 1000000000 and 9999999999, show as x.xxB
        if (number >= 1000000000 && number <= 9999999999) {
            const result = (number / 1000000000).toFixed(2);
            return parseFloat(result) + 'B';
        }

        // For numbers 10000000000 and above, show as x.xxB
        if (number >= 10000000000) {
            const result = (number / 1000000000).toFixed(1);
            return parseFloat(result) + 'B';
        }

        return number.toString();
    }
}

